@font-face {
    font-family: 'Ferrari-Sans';
    font-weight: 700;
    src: local('Ferrari-Sans'), url(./assets/fonts/Ferrari-SansBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Ferrari-Sans';
    font-weight: 400;
    src: local('Ferrari-Sans'), url(./assets/fonts/Ferrari-SansRegular.ttf) format('truetype');
}
